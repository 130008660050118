import { BankInfoModel } from "@/servers/payments/models/BankInfoModel";
import { DebtModel } from "@/servers/payments/models/DebtModel";
import { PayMethodModel } from "@/servers/payments/models/PayMethodModel";
import { create } from "zustand";

type State = {
    step: number;
    transaction: Array<DebtModel>;
    bankCode: Pick<BankInfoModel, "maNganHang"> | null;
    methodId: Pick<PayMethodModel, "id"> | null;
    summary: DebtModel;
};
type Action = {
    setStep: (value: number) => void;
    setTransaction: (value: DebtModel) => void;
    clearTransaction: () => void;
    setBankCode: (code: Pick<BankInfoModel, "maNganHang">) => void;
    setMethodId: (id: Pick<PayMethodModel, "id"> | null) => void;
};

const usePaymentParams = create<State & Action>((set, get) => ({
    summary: {
        maHocSinh: "",
        transactionId: 1,
        maLoaiPhi: 1,
        tenLoaiPhi: "",
        nam: 1,
        thang: 1,
        phaiThu: 1,
        chinhSach: 1,
        chietKhau: 1,
        daDong: 1,
        conPhaiThu: 0,
        noNgoaiNamHoc: false,
        daHoanThanh: false,
    },
    step: 0,
    clearTransaction: () => {
        set({ transaction: [] })
    },
    setStep: (value) => {
        set({ step: value });
    },
    transaction: [],
    setTransaction: (value) => {
        var arr = [...get().transaction];
        arr =
            arr.findIndex(e => e.transactionId === value.transactionId) === -1
                ? [...arr, value]
                : arr.filter((e) => e.transactionId !== value.transactionId);
        if (arr.length > 0 && get().step < 1) set({ step: 1 });
        set({ transaction: arr });
    },
    // setTransactionIds: (id) => {
    //     var arr = [...get().transaction];
    //     arr =
    //         arr.includes(id) === false
    //             ? [...arr, id as any]
    //             : arr.filter((value) => value !== (id as any));
    //     if (arr.length > 0) set({ step: 2 });
    //     set({ transaction: arr });
    // },
    bankCode: null,
    setBankCode: (code) => {
        set({ bankCode: code });
        if (get().step < 2) set({ step: 2 });
    },
    methodId: null,
    setMethodId: (id) => {
        set({ methodId: id });
        if (get().step < 3) set({ step: 3 });
    },
}));

export default usePaymentParams;
