import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import { en } from "./locales/en";
import { km } from "./locales/km";
const resources = {
  en: {
    translation: en,
  },
  km: {
    translation: km,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: process.env.REACT_APP_LANGUAGE_DEFAULT,
    fallbackLng: [process.env.REACT_APP_LANGUAGE_DEFAULT],
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ["cookie"],
      caches: ["cookie"],
    },
    react: { useSuspense: true },
  });
