import { FC, Suspense } from "react";
import { RouterProvider } from "react-router-dom";
import routes from "./routes";

const Router: FC = () => {
    return (
        <Suspense>
            <RouterProvider router={routes} />
        </Suspense>
    );
};

export default Router;
