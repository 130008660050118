import PayPage from "@/app/pages/payment/PaymentPage";
import axios from "axios";
import {
  Route,
  Routes,
  createBrowserRouter,
  createRoutesFromElements,
  redirect,
} from "react-router-dom";

export default createBrowserRouter(
  createRoutesFromElements(
    <Route path="/">
      <Route
        index
        lazy={async () => {
          return {
            Component: (await import("@/app/pages/home")).default,
          };
        }}
      />
      <Route
        path="student/:code"
        lazy={async () => {
          return {
            Component: (await import("@/app/layouts/layouts")).default,
          };
        }}
      >
        {/* <Route
                    index
                    lazy={async () => {
                        return {
                            Component: (
                                await import("@/app/pages/payment/SearchPage")
                            ).default,
                        };
                    }}
                /> */}
        <Route
          index
          lazy={async () => {
            return {
              Component: (await import("@/app/pages/payment/PaymentPage"))
                .default,
            };
          }}
        />
        <Route
          path="bills"
          lazy={async () => {
            return {
              Component: (await import("@/app/pages/payment/BillPage")).default,
            };
          }}
        />
      </Route>
      <Route
        path="*"
        lazy={async () => {
          return {
            Component: (await import("@/app/pages/unknown")).default,
          };
        }}
      />
      <Route path="payment/invoice">
        <Route path="wingbankcancelsdk/:orderReferenceNo/:hash" />
        <Route
          path="wingbankreturnsdk/:orderReferenceNo/:hash"
          lazy={async () => {
            return {
              Component: (await import("@/app/pages/redirect/SuccessPage"))
                .default,
            };
          }}
        />
      </Route>
    </Route>
  )
);
