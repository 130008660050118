import { FC } from "react";
import { Alert, Snackbar } from "@mui/material";
import Router from "@/Router";
import { useSnackbar } from "./hooks";

const App: FC = () => {
  const { open, closeSnackbar } = useSnackbar();
  console.log(useSnackbar.getState());
  return (
    <>
      <Router />
      <Snackbar open={open} autoHideDuration={6000} onClose={closeSnackbar}>
        <Alert
          onClose={closeSnackbar}
          severity="info"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {useSnackbar.getState().message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default App;
