import { create } from "zustand";

type State = {
        open: boolean,
        message: string
};
type Action = {
        openSnackbar: (message: string) => void
        closeSnackbar: () => void
};

const useSnackbar = create<State & Action>((set, get) => ({
        open: false,
        message: "",
        openSnackbar: (message) => set({ open: true, message }),
        closeSnackbar: () => set({ open: false })
}))

export default useSnackbar